import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import MediaTile from "../components/MediaTile"
import Button from "../components/Button"
import BasicContentSection from "../components/BasicContentSection"
import { css } from "emotion"

import img_header from "../images/2022-02/capitol-4.jpg"
import agriculture from "../images/2022-02/agriculture.jpg"
import businessClimate from "../images/business-climate.jpg"
import education from "../images/2022-02/education.jpg"
import fiscalResponsibility from "../images/fiscal-responsibility.jpg"
import freedomOfSpeech from "../images/freedom-of-speech.jpg"
import healthcare from "../images/healthcare.jpg"
import lawEnforcement from "../images/law-enforcement.jpg"
import proLife from "../images/pro-life.jpg"
import religiousLiberty from "../images/religious-liberty.jpg"
import rightToBearArms from "../images/2022-02/right-to-bear-arms.jpg"
import tortReform from "../images/2022-02/tort-reform.jpg"
import downtownStreet from "../images/downtown-calhoun-st.jpg"
import statueOfLiberty from "../images/2022-02/reverse-statue-of-liberty.jpg"
import thomasJefferson from "../images/2022-02/thomas-jefferson-building.jpg"
import bankBuilding from "../images/2022-02/bank-building.jpg"

// Image imports
import icon from "../images/gatsby-icon.png"

const Issues = () => (
  <Layout>
    <SEO title="About" keywords={[`issues`, `Alex Riley`, `info`]} />
    <MediaTile
      backgroundImage={img_header}
      backgroundPosition="50% 50%"
      backgroundColor="blue"
      color="white"     
      minHeight="500px"
    >
      {/* <h1>Issues</h1> */}
    </MediaTile>
    <div style={{backgroundColor:"#0A273B", width:"100%", height:"auto"}}>
        <h1 className="hero-h1">                                                    
              <span className="hero-text hero-h1">Issues</span>          
          </h1>    
      </div>

    <MediaTile backgroundColor="white" color="#0A273B">
      <h1>Protect Life</h1>
    </MediaTile>
    <BasicContentSection imgPosition="center center" href={proLife}>
      <b>Pro-Life -</b> One of the most important functions of government is to protect the lives of its citizens. This is true for all, but especially 
      true for the tiniest, the most vulnerable, and those with no voice or ability to protect themselves. Alex is pro-life and he fights to ensure that all 
      human life is protected, beginning at conception. He has co-sponsored legislation to ensure abortion providers in Missouri receive no taxpayer dollars.
    </BasicContentSection>
    <BasicContentSection imgRight imgPosition="center center" href={healthcare}>
      <b>Healthcare -</b> Missourians should be empowered to make the best decisions possible for themselves when it comes to their health. Government is 
      too active in our citizens’ healthcare and government interventions often result in worse care at a higher price. Rather than intervene further in 
      healthcare, government should reduce its role and allow the free market to do what it does best - foster innovation and competition. This ultimately results 
      in better healthcare at a lower cost for Missouri’s citizens. Alex supports legislation that reduces government’s role in healthcare and promotes free market healthcare solutions. 
    </BasicContentSection>
    <BasicContentSection imgPosition="center center" href={lawEnforcement}>
      <b>Law Enforcement -</b> Our brave law enforcement officers put their lives on the line to protect us, our families, and our communities every day. As crime rates continue 
      to rise across our city, state, and nation, their work is more important than ever. Alex supports our state and local law enforcement officers in the life-saving work they do 
      everyday and he opposes any effort to defund the police. As state representative, Alex was proud to vote in favor of the Law Enforcement Bill of Rights which provides important 
      protections for our police. 
    </BasicContentSection>

    <MediaTile
      backgroundImage={thomasJefferson}
      backgroundPosition="center center"
      backgroundColor="white"
      color="white"
    >
      <h1>
        "The care of human life and happiness, and not their destruction, is the
        first and only object of good government."
      </h1>
      <h3>-Thomas Jefferson</h3>
    </MediaTile>
    <MediaTile backgroundColor="white" color="#0A273B">
      <h1>Defend Liberty</h1>
    </MediaTile>
    <BasicContentSection
      imgRight
      imgPosition="center center"
      href={religiousLiberty}
    >
      <b>Religious liberty -</b> One of the primary reasons our forefathers came to the United States was to escape oppression because of their religious beliefs. Our nation’s 
      founders recognized religious freedom was so important, that this was the first right they listed in the Bill of Rights. Alex is a strong advocate for religious liberty and he 
      fights to defend the right of Missourians to practice their faith, free from government oppression. He sponsored the Missouri Religious Freedom Protection Act and was named 
      Freshman Legislator of the Year in 2021 by the Speaker of the House for his work defending religious liberties.
    </BasicContentSection>
    <BasicContentSection imgPosition="center center" href={freedomOfSpeech}>
      <b>Free Speech -</b> The right to express oneself through speech is essential and government must not interfere with it. Too often in this era of oversensitivity and excessive 
      political correctness, individuals with unpopular or opposing viewpoints are silenced. This is a dangerous and slippery slope. Everyone should have the opportunity for their voices 
      to be heard - even those with unpopular opinions. Alex defends our right to speak freely and opposes government attempts to silence people.  
    </BasicContentSection>
    <BasicContentSection
      imgRight
      imgPosition="center center"
      href={rightToBearArms}
    >
      <b>Right to Bear Arms -</b> Protecting oneself and one’s family is a fundamental right. Many Missourians choose to own firearms for protection, for hunting, and for other sporting purposes. 
      The right to do so is enshrined in both the United States and Missouri Constitutions. As a long-time member of the NRA and supporter of responsible firearm ownership, Alex fights to protect 
      and preserve our right to bear arms. As state representative, Alex co-sponsored the Second Amendment Preservation Act and has supported numerous other bills designed to protect our citizens 
      from government overreach.
    </BasicContentSection>
    <MediaTile
      backgroundImage={statueOfLiberty}
      backgroundPosition="center center"
      backgroundColor="white"
      color="white"
    >
      <h1>
        "Liberty must at all hazards be supported. We have a right to it,
        derived from our Maker."
      </h1>
      <h3>-John Adams</h3>
    </MediaTile>
    <MediaTile backgroundColor="white" color="#0A273B">
      <h1>Foster Prosperity</h1>
    </MediaTile>
    <BasicContentSection imgPosition="center center" href={businessClimate}>

      <b>Business Climate -</b> As we work together to rebuild our state's economy and reduce Missouri's and Springfield's high poverty rates, our government needs to unshackle Missouri’s job 
      creators. We must establish a business climate with better economic conditions which results in higher paying jobs for Missouri’s citizens. History has shown that free market capitalism 
      is the most successful economic system at reducing poverty and bringing about wide-spread prosperity. However, excessive government intervention, regulation, taxation, and licensing requirements 
      burden our innovators and stifle economic growth. Alex fights to reduce burdens on our state’s economy, defends free market capitalism, and pushes for policies that foster economic growth. One of 
      the first bills he passed into law cut excessive government red tape on a key sector of our state's economy.  
    </BasicContentSection>
    <BasicContentSection imgRight imgPosition="center center" href={education}>
      <b>Education -</b> Missouri needs a strong education system to ensure our state’s future leaders, workers, and citizens receive the tools they need to succeed. While government can play an 
      important role in education, it must realize there is no “one size fits all” approach. Education policy is best made at the local level where parents, guardians, and the community have the 
      most say in how children are educated. While a strong public school system is necessary and may be the best option for many students, it may not be the best option for all. For some, the best 
      option may be home school, private school, or faith-based school. Parents and guardians, not government, should make the decision about which school option works best for their families. Alex 
      supports legislation leaving education issues at the local level and allowing families to choose the best option for their children. In his first legislative session, Alex cast the deciding vote 
      in passing a critical school choice bill out of the Missouri House of Representatives and into law.
    </BasicContentSection>
    <BasicContentSection imgPosition="center center" href={tortReform}>
      <b>Tort Reform -</b> Tort laws relate to lawsuits involving injuries to people or property. Some of these cases have merit, but too often Missouri’s tort system is abused. Because of our anti-business 
      tort law reputation, out-of-state attorneys and plaintiffs frequently flock to our state’s courthouses to sue our state’s job creators. This places a great burden on our businesses and limits our 
      ability to create and attract new businesses. Alex has sponsored legislation for tort reform to solve this problem. 
    </BasicContentSection>
    <BasicContentSection
      imgRight
      imgPosition="center center"
      href={fiscalResponsibility}
    >
      <b>Fiscal Responsibility -</b> Missourians have to balance their household budgets and plan according to their incomes. In the same way, our state government must live within its means and balance 
      its budget. If the state lacks money to pay for something, it needs to do the same thing Missouri’s families do - live without, or find a way to pay for it. Rather than going into debt or increasing 
      taxes on Missourians, Alex believes in increasing revenue through economic growth and new jobs - not increasing the burden on our existing taxpayers. Alex has voted against massive tax increases and 
      is instrumental in ensuring our state has a balanced budget.
    </BasicContentSection>
    <BasicContentSection      
      imgPosition="center center"
      href={agriculture}
    >
      <b>Agriculture -</b> Agriculture is a $94 billion dollar industry in Missouri and the state has the second highest number of farms in the United States. Though farming is critical to our state’s 
      economy, government burdens our farming families with unnecessary and costly regulations. Our farmers live off of and rely on the land, so they are going to take care of it. They do not need 
      government bureaucrats dictating how to operate their farms. Alex advocates for fewer government regulations on agriculture to lighten the burden on Missouri’s farmers. 
    </BasicContentSection>

    <MediaTile
      backgroundImage={bankBuilding}
      backgroundPosition="center center"
      backgroundColor="white"
      color="white"
    >
      <h1>
        "Lasting economic recovery [has] to be built on the solid rock of the
        American free enterprise system."
      </h1>
      <h3>-Ronald Reagan</h3>
    </MediaTile>
    <MediaTile backgroundColor="white" color="#0A273B">
      <h1>Keep Up to Date</h1>
      <div>
        <Button href="/up-to-date">Sign Up</Button>
      </div>
    </MediaTile>
  </Layout>
)

export default Issues
